import React from "react";
import styled from "styled-components";
import ContentPage from "../homePage/contentPage";
import ReadMore from "../readMore";

const Container = styled.div(
  (props) => `
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  h1 {
    color: var(--ultramarine);
    margin-bottom: 2rem;
  }

  .p1 {
    margin: 1.6rem 0 2.4rem 0;
    max-width: ${props.mobile ? "" : "80%"};
    text-align: center;
  }
`);

const LandingLegislation = ({mobile}) => {
  return (
    <ContentPage background={mobile ? "" : "var(--background2)"} mobile={mobile}>
      <Container mobile={mobile}>
        <h1>Legislation</h1>
        <p className="p1">
          <strong>(How to hire in Portugal)</strong>
          <br />
          <br />
          Employing in Portugal requires employers to own a legal entity in the
          country and manage payroll, tax, benefits and compliance through their
          own in-country resources. The complexity of employment regulations in
          Portugal makes full compliance with employment laws a burdensome
          process.
        </p>
      </Container>
      {mobile && <ReadMore location="#quick_overview"/>}
    </ContentPage>
  );
};

export default LandingLegislation;
