import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import ContentPage from "../../homePage/contentPage";
import Contributions from "./contributions";

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  p {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    margin: 3.4rem 0 1.6rem 0;
  }

  .taxesImg {
    margin-top: 4.8rem;
  }
`;

const Taxes = ({ mobile }) => {
  return (
    <Wrapper>
      <ContentPage
        mobile={mobile}
        background={mobile ? "var(--background2)" : ""}
      >
        <div id="taxes" className="sectionWrapper">
          {!mobile && (
            <StaticImage
              src="../../../images/rectangle-legislation@3x.png"
              className="legislation_rectangle"
              alt="rectangle shape"
            ></StaticImage>
          )}
          <Container>
            <h2>Taxes</h2>
            <p>
              Portugal has an income tax and social security tax. Income tax
              rates range from 14.5 percent to 48 percent, based on an
              employee’s income. <br />
              <br />
              Both employer and employee need to pay social security tax. For
              employees, the tax rate is 11 percent. The tax rate for employers
              is 23.75 percent. Contributions to social security help cover the
              cost of pensions, family benefits, and unemployment compensation.
            </p>
            <h3>Contributions</h3>
            <Contributions mobile={mobile} />
            {!mobile && (
              <StaticImage
                src="../../../images/taxes@3x.png"
                className="taxesImg"
                alt="Employer Taxes of certain countries"
              ></StaticImage>
            )}
          </Container>
        </div>
      </ContentPage>
      {mobile && (
        <StaticImage
          src={"../../../images/taxesMobile@3x.webp"}
          className="taxesImg"
          alt="Employer Taxes of certain countries"
        ></StaticImage>
      )}
    </Wrapper>
  );
};

export default Taxes;
