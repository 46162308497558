import React from "react";
import styled from "styled-components";

const ContentPageContainer = styled.div(
  (props) => `
    height: ${props.smaller && !props.mobile ? "calc(70vh - 80px)" : ""};
    background-color: ${props.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${props.mobile ? "5rem 3.2rem" : "6vw 9vw 6vw 6vw"};
    padding-top: ${props.paddingTop};
    position: relative;
    overflow: visible; /* previously hidden */

    h1{
      font-size: ${props.mobile ? "2.8rem" : "5.6rem"} !important;
      font-weight: bold !important;
    }

    h2{
      font-size: ${props.mobile ? "2.4rem" : "4rem"} !important;
      margin: ${props.mobile ? " 0 0 2rem 0" : "1.6rem 0 3.6rem 0"};
      text-align: ${props.mobile ? "center" : ""} !important;
    }
  
    h3{
      font-size: ${props.mobile ? "2rem" : "2.4rem"} !important;
      text-align: ${props.mobile ? "center" : ""} !important;
    }
  
    .p1{
      color: var(--cobalt);
      font-size: ${props.mobile ? "1.6rem" : "2rem"} !important;
    }

    .p2{
      font-size: ${(props) => (props.mobile ? "1.6rem" : "1.8rem")};
      font-weight: 500;
      margin: 2rem 0
    }
  
    .p3{
      font-size: ${props.mobile ? "1.6rem" : "1.8rem"};
      margin: ${props.mobile ? "0" : "2rem 0 4.8rem 0"} !important;
    }
  
    .p4{
      font-size: 1.6rem;
      font-weight: 500;
      margin: 0;
    }

  .rectangle {
    position: absolute;
    left: 0;
    top: -14vw;
    z-index: -1;
  }

  .legislation_rectangle {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: fit-content;
  }

.sectionWrapper{
  scroll-margin-top: 12rem;
}
`
);

const ContentPage = ({ children, background, smaller, mobile, paddingTop }) => {
  return (
    <ContentPageContainer
      background={background}
      smaller={smaller}
      mobile={mobile}
      paddingTop={paddingTop}
      className={smaller && !mobile ? "landing-content-page content-page" : ""}
    >
      {children}
    </ContentPageContainer>
  );
};

export default ContentPage;
