import React from "react";
import styled from "styled-components";
import ContentPage from "../../homePage/contentPage";
import Type from "./typeOfContracts";

const Container = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    margin: 3.4rem 0 1.6rem 0;
  }

  p {
    margin: 0;
    font-size: ${props.mobile ? "1.6rem" : "1.8rem"};
  }
`);

const Contracts = styled.div(
  (props) => `
  display: flex;
  flex-direction: ${props.mobile ? "column" : ""};
  justify-content: space-between;
  width: 100%;
  margin: ${props.mobile ? "3rem 0 2rem 0" : "2.4rem 0 4.8rem 0"};
`);

const ContractTypes = ({mobile}) => {
  return (
    <ContentPage mobile={mobile}>
      <div id="contract_types" className="sectionWrapper">
        <Container mobile={mobile}>
          <h2>Contract Types</h2>
          <p>
            Typically, when hiring someone in Portugal, you hire them for an
            <strong>indefinite period.</strong> As long as things work out
            between the employer and employee, the employee can continue to work
            for the company for as long as necessary. If the employment isn’t of
            indefinite duration, a contract is usually needed.{" "}
            {mobile && <br />}
            {mobile && <br />}
            <strong>Types of employment contracts include:</strong>
          </p>
          <Contracts mobile={mobile}>
            <Type contract="Fixed-term" mobile={mobile}/>
            <Type contract="Unfixed-term" mobile={mobile}/>
            <Type contract="Intermittent" mobile={mobile}/>
            <Type contract="Part-time" mobile={mobile}/>
            <Type contract="Telecommuting" mobile={mobile}/>
            <Type contract="Temporary" mobile={mobile} span={mobile ? "hide" : ""}/>
          </Contracts>
          <p>
            The beginning period of the contract is typically a trial period
            that lasts from 90 to 180 days, depending on the type of work a
            person is hired to do and the required skill level. The trial period
            can be 15 to 30 days for fixed-term contracts.{" "}
            {mobile && <br />}
            {mobile && <br />}
            <strong>
              The purpose of a trial period is to let an employee and employer
              see if the arrangement is appropriate and that both parties would
              like to continue.
            </strong>{" "}
            Considering that fixed-term contracts are strongly regulated by the
            employment regulations, it’s important to know why the position is
            being offered as a temporary position and not an indefinite one.
          </p>
        </Container>
      </div>
    </ContentPage>
  );
};

export default ContractTypes;
