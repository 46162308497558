import React from "react";
import styled from "styled-components";
import ContentPage from "../homePage/contentPage";

const Container = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: ${props.mobile ? "1rem" : "2rem"};
  }

  .underline{
      text-decoration: ${props.mobile ? "" : "underline"};
  }

  p{
    font-size: ${props.mobile ? "1.6rem" : "1.8rem"};
  }
`);

const SocialSecurity = ({mobile}) => {
  return (
    <ContentPage mobile={mobile}>
      <div id="social_security" className="sectionWrapper">
        <Container mobile={mobile}>
          <h2>Compliance with Social Security obligations</h2>
          <p>
            After the employee recruitment phase, the company must <span className="underline">register its
            employees with the Social Security</span>. Monthly, it is responsible for
            the payment of social security contributions and for deducting this
            amount from the remuneration paid to employees.
          </p>
        </Container>
      </div>
    </ContentPage>
  );
};

export default SocialSecurity;
