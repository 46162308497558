import React from "react";
import styled from "styled-components";
import ContentPage from "../homePage/contentPage";

const Container = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: ${props.mobile ? "1rem" : "2rem"};
  }

  .underline {
    text-decoration: ${props.mobile ? "" : "underline"};
  }
`);

const IncentivesForHiring = ({mobile}) => {
  return (
    <ContentPage mobile={mobile} paddingTop={"0"}>
      <div id="incentives_for_hiring" className="sectionWrapper">
        <Container mobile={mobile}>
          <h2>Support and incentives for hiring</h2>
          <p>
            IEFP has several <span className="underline">supports and incentives for hiring</span> that consist of
            granting financial support to companies that sign employment
            contracts.
          </p>
          <p>
            There are also other <span className="underline">measures for exemption or reduction of
            contributions borne by the employer</span>, under the responsibility of the
            Social Security Institute, IP.
          </p>
        </Container>
      </div>
    </ContentPage>
  );
};

export default IncentivesForHiring;
