import React from "react";
import styled from "styled-components";
import SideBarSocial from "./sidebar/sidebar-social";
import $ from "jquery";
import { Component } from "react";

const SideBarContainer = styled.div`
  width: 27vw;
  border-radius: 4px;
  border-right: solid 2px #ededed;
  overflow: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  background-color: white;
`;

const SideBarContentWrapper = styled.div`
  height: 40vh;
  padding: 30px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  //height: 193px;
  width: 27vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-right: solid 2px #373737;
`;

const SideBarContents = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text);
    margin-bottom: 10px;
    padding-left: 15px;
  }

  a {
    text-decoration: none;
    color: var(--text);
    font-size: 1.6rem;
    margin: 10px 0;
    padding-left: 15px;
  }

  .active {
    color: var(--accent);
    border-left: 2px solid var(--accent);
  }
`;

class SideBar extends Component {
  componentDidMount() {
    // Add smooth scrolling to all links
    $(".nav-item").on("click", function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          800,
          function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          }
        );
      } 
    });
  }

  render() {
    return (
      <SideBarContainer className="sidebar">
        <SideBarContentWrapper>
          <SideBarSocial />

          {!this.props.legislation && (
            <SideBarContents>
              <h3>Table of Contents</h3>
              <a
                href="#nearshore_content"
                className="nav-item nearshore_content active"
              >
                <strong>Nearshore.</strong> A jump in the era of digital
                transformation
              </a>
              <a
                href="#advantages_content"
                className="nav-item advantages_content"
              >
                <strong>Advantages.</strong> Why choose this solution?
              </a>
              <a
                href="#whyportugal_content"
                className="nav-item whyportugal_content"
              >
                <strong>Why Portugal?</strong> 5 reasons to choose Portugal
              </a>
              <a
                href="#areaofaction_content"
                className="nav-item areaofaction_content"
              >
                <strong>Area of action.</strong> Which countries hire in
                Portugal and salary comparison
              </a>
              <a href="#news_content" className="nav-item news_content">
                <strong>Latest news</strong>
              </a>
            </SideBarContents>
          )}
          {this.props.legislation && (
            <SideBarContents>
              <h3>Legislation</h3>
              <a
                href="#quick_overview"
                className="nav-item quick_overview active"
              >
                <strong>Quick Overview</strong>
              </a>
              <a href="#taxes" className="nav-item taxes">
                <strong>Taxes</strong>
              </a>
              <a href="#contract_types" className="nav-item contract_types">
                <strong>Contract types</strong>
              </a>
              <a href="#costs_of_hiring" className="nav-item costs_of_hiring">
                <strong>Cost of Hiring an Employee in Portugal</strong>
              </a>
              <a href="#social_security" className="nav-item social_security">
                <strong>Compliance with Social Security obligations</strong>
              </a>
              <a
                href="#incentives_for_hiring"
                className="nav-item incentives_for_hiring"
              >
                <strong>Support and incentives for hiring</strong>
              </a>
            </SideBarContents>
          )}
        </SideBarContentWrapper>
      </SideBarContainer>
    );
  }
}

export default SideBar;
