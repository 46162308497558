import React from "react";
import styled from "styled-components";
import { CostsOfHiringCollapsibles } from "./costsOfHiringCollapsibles";
import { OverviewCollapsibles } from "./overviewCollapsibles";

const Wrapper = styled.div(
  (props) => `
  h4 {
    font-size: ${props.mobile ? "1.6rem !important" : ""};
    margin: 0;
    cursor: pointer;
  }

  h5 {
    font-size: 1.6rem;
    margin: 2.4rem 0 0 0;
  }

  p {
    margin: 0.8rem 0 0 0;
    padding-left: 2.5rem;
    font-size: 1.6rem;
  }

  .paragraph p {
    padding-left: 0;
  }

  .collapsibleIcon {
    margin-right: 0.5rem;
    color: var(--accent);
    width: 2rem !important;
    height: 2rem !important;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .paragraph {
    padding-left: 3.8rem;
  }
`);

const Collapsibles = ({ page, mobile }) => {
  return (
    <Wrapper mobile={mobile}>
      {page === "overview" && <OverviewCollapsibles /> }
      {page === "costsOfHiring" && <CostsOfHiringCollapsibles /> }
    </Wrapper>
  );
};

export default Collapsibles;
