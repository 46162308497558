import React from "react";
import styled from "styled-components";

const Container = styled.div(
  (props) => `
  border-radius: 14px;
  padding: ${props.mobile ? "1rem 0" : "1.2rem 1rem 1.8rem 1.2rem"};
  box-shadow: ${props.mobile ? "" : "0 5px 12px 0 rgba(0, 0, 0, 0.12)"};
  display: flex;
  flex-direction: column;

  span {
    width: 17px;
    height: 3px;
    background-color: ${props.mobile ? "lightgray" : "var(--blue)"};
    border-radius: 30%;
  }

  p{
      margin: 0 0 1rem 0 !important;
      color: ${props.mobile ? "var(--accent)" : "var(--blue)"};
      font-weight: bold;
      font-size: ${props.mobile ? "1.6rem !important" : "1.4rem !important"};
  }
`);

const Type = ({ contract, mobile, span }) => {
  return (
    <Container mobile={mobile}>
      <p>{contract}</p>
      {span!=="hide" ? <span></span> : ""}
    </Container>
  );
};

export default Type;
