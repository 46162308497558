import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  margin: 3rem 0 0 0;

  .down {
    display: none;
  }
`;

const CollapsibleTitle = ({ title }) => {
  return (
    <Wrapper className="collapsible">
      <FontAwesomeIcon icon={"caret-right"} className="collapsibleIcon right" />
      <FontAwesomeIcon icon={"caret-down"} className="collapsibleIcon down" />
      <h4>{title}</h4>
    </Wrapper>
  );
};

export default CollapsibleTitle;
