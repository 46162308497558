import React from "react";
import styled from "styled-components";

const Container = styled.div(
  (props) => `
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 5rem;
  flex-direction: ${props.mobile ? "column" : ""};

  p{
    font-size: ${props.mobile ? "1.4rem !important" : ""};
  }
`
);

const ContributionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 40%; */

  h5 {
    margin: 1.5rem 0 0 0;
  }
`;

const Row = styled.div`
  display: flex;

  p {
    margin: 0.5rem 0;
    white-space: nowrap;
  }

  p:first-child {
    margin-right: 3rem;
    width: 20%;
  }
`;

const Contributions = ({ mobile }) => {
  return (
    <Container mobile={mobile}>
      <ContributionWrapper>
        <h5>Employer Payroll Contributions</h5>
        <Row>
          <p>23.75%</p>
          <p>Social Security</p>
        </Row>
        <Row>
          <p>1.75%</p>
          <p>Labor Accident Insurance</p>
        </Row>
        <Row>
          <p>1.00%</p>
          <p>WGF (wage guarantee fund)</p>
        </Row>
        <Row>
          <p>26.50%</p>
          <p>Total Employment Cost</p>
        </Row>
      </ContributionWrapper>

      <ContributionWrapper>
        <h5>Employee Payroll Contributions</h5>
        <Row>
          <p>14.50%</p>
          <p>up to 7,212.00 EUR</p>
        </Row>
        <Row>
          <p>23.00%</p>
          <p>7,113.00 EUR to 10,732.00 EUR</p>
        </Row>

        <h5>Employee Income Tax</h5>
        <Row>
          <p>28.50%</p>
          <p>10,733.00 EUR to 20,322.00 EUR</p>
        </Row>
        <Row>
          <p>35.00%</p>
          <p>20,323.00 EUR to 25,075.00 EUR</p>
        </Row>
        <Row>
          <p>37.00%</p>
          <p>25,076.00 EUR to 36,967.00 EUR</p>
        </Row>
        <Row>
          <p>45.00%</p>
          <p>36,968.00 EUR to 80,967.00 EUR</p>
        </Row>
        <Row>
          <p>48.00%</p>
          <p>80,823.00 EUR and above</p>
        </Row>
      </ContributionWrapper>
    </Container>
  );
};

export default Contributions;
