import React from "react";
import { Component } from "react";
import styled from "styled-components";
import ContentPage from "../../homePage/contentPage";
import Collapsibles from "./collapsibles";
import OverviewDetails from "./overviewDetails";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;

  h2 {
    text-align: center;
  }
`;

class QuickOverview extends Component {
  componentDidMount() {
    var coll = Array.from(document.getElementsByClassName("collapsible"));
    var i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        var iconRight = this.querySelector(".right");
        var iconDown = this.querySelector(".down");

        if (content.style.maxHeight) {
          content.style.maxHeight = null;
          iconRight.style.display = "inline-block";
          iconDown.style.display = "none";
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
          iconRight.style.display = "none";
          iconDown.style.display = "inline-block";
        }
      });
    }
  }

  render() {
    return (
      <ContentPage mobile={this.props.mobile}>
        <div id="quick_overview" className="sectionWrapper">
          <Container>
            <h2>Quick Overview</h2>
            {!this.props.mobile && <OverviewDetails />}
            <Collapsibles page={"overview"} mobile={this.props.mobile}/>
          </Container>
        </div>
      </ContentPage>
    );
  }
}

export default QuickOverview;
