import React from "react";
import CollapsibleTitle from "./collapsibleTitle";

export const CostsOfHiringCollapsibles = () => {
  return (
    <div>
      <CollapsibleTitle title="Advertising costs" />
      <div className="content">
        <p>
          Although free job boards exist, you’ll most likely want to pay to list
          your job posting on relevant boards.
        </p>
      </div>

      <CollapsibleTitle title="Travel costs" />
      <div div className="content">
        <p>
          You might need to travel to Portugal to meet with candidates and make
          your hiring decisions. You might also decide to cover travel expenses
          for candidates.
        </p>
      </div>

      <CollapsibleTitle title="Human resources costs" />
      <div div className="content">
        <p>
          If you don’t have a dedicated HR team on staff, someone will have to
          set aside time from their schedule to review applications and
          interview candidates. Even if you have an HR department, they’ll need
          to carve out time to complete the hiring process.
        </p>
      </div>

      <CollapsibleTitle title="Onboarding costs" />
      <div div className="content">
        <p>
          Onboarding a new employee requires time and money. In Portugal, you
          need to provide new team members with a certain number of training
          hours, which can cost you in terms of lost productivity.
        </p>
      </div>
    </div>
  );
};
