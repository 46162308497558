import "../components/font-awesome";
import * as React from "react";
import styled from "styled-components";
import SideBar from "../components/homePage/sidebar";
import useSiteMetadata from "../hooks/useSiteMetadata";
import Layout from "../components/layout";
import Seo from "../components/seo";
import LandingLegislation from "../components/legislation/landingLegislation";
import QuickOverview from "../components/legislation/QuickOverview/quickOverview";
import ContractTypes from "../components/legislation/contractTypes/contractTypes";
import CostsOfHiring from "../components/legislation/costsOfHiring";
import SocialSecurity from "../components/legislation/socialSecurity";
import IncentivesForHiring from "../components/legislation/incentivesForHiring";
import { useMediaQuery } from "react-responsive";
import Taxes from "../components/legislation/taxes/taxes";

const Container = styled.div`
  display: flex;
  height: 100%;
  margin-top: 8rem;
  scroll-behavior: smooth;
`;

const MainContent = styled.div`
  flex: 1;

  .sectionWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rectangle {
    position: absolute;
    z-index: -1;
    transform: translateY(-23rem);
  }

  h2 {
    margin-top: 0;
  }

  h4 {
    font-size: 1.8rem;
  }
`;

const SeoWrapper = styled.div``;

const isBrowser = () => typeof window !== "undefined";
if (isBrowser()) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const sections = document.getElementsByClassName("sectionWrapper");
  const navLi = document.getElementsByClassName("nav-item");

  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  function scrollFunc() {
    //Know if user is on legislation page and give this window a scroll event to track current link
    var url = window.location.href.split("/");
    if (url.includes("legislation")) {
      for (var i = 0; i < sections.length; i++) {
        const sectionTop = offset(sections[i]).top;
        const sectionHeight = sections[i].clientHeight + 1000;
        if (
          window.pageYOffset >= sectionTop - sectionHeight / 3 &&
          window.pageYOffset !== 0
        ) {
          current = sections[i].getAttribute("id");
        }
      }
      for (var j = 0; j < navLi.length; j++) {
        navLi[j].classList.remove("active");
        if (navLi[j].classList.contains(current)) {
          navLi[j].classList.add("active");
        }
      }
    }
  }

  var current = "quick_overview";
  window.addEventListener("scroll", scrollFunc);
}

const Legislation = () => {
  const { siteName } = useSiteMetadata();
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  return (
    <SeoWrapper>
      <Seo title={siteName + " | Legislation"} />
      <Layout>
        <div className="legislation">
          <Container className="main_content">
            <SideBar legislation={true} />
            <MainContent>
              <LandingLegislation mobile={isMobile ? true : false} />
              <QuickOverview mobile={isMobile ? true : false} />
              <Taxes mobile={isMobile ? true : false} />
              <ContractTypes mobile={isMobile ? true : false} />
              <CostsOfHiring mobile={isMobile ? true : false} />
              <SocialSecurity mobile={isMobile ? true : false} />
              <IncentivesForHiring mobile={isMobile ? true : false} />
            </MainContent>
          </Container>
        </div>
      </Layout>
    </SeoWrapper>
  );
};

export default Legislation;
