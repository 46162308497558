import React from "react";
import styled from "styled-components";
import ContentPage from "../homePage/contentPage";
import Collapsibles from "./QuickOverview/collapsibles";

const Container = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;

  .costsParagraph{
      font-size: ${props.mobile ? "1.6rem" : "1.8rem"};
      margin-top: 3.3rem;
  }

  .p_costs{
    margin: 0;
  }

  h2{
    margin: 0 0 1.2rem 0 !important;
  }
`);

const CostsOfHiring = ({mobile}) => {
  return (
    <ContentPage background="var(--background2)" mobile={mobile}>
      <div id="costs_of_hiring" className="sectionWrapper">
        <Container mobile={mobile}>
          <h2>Cost of Hiring an Employee in Portugal</h2>
          <p className="p_costs">
            Some of the expenses associated with making a new hire include:
          </p>
          <Collapsibles page="costsOfHiring" mobile={mobile}/>
          <p className="costsParagraph">
            Working with an Employer of Record (EOR) can help to reduce your
            onboarding and other hiring-related costs. The EOR sets up payroll
            and ensures that all aspects of the hiring process, such as the
            employment contract, comply with Portugal’s labor code. Instead of
            dealing with the fine details of hiring and human resources, you can
            focus on building your new employees’ skills and growing your
            business.
          </p>
        </Container>
      </div>
    </ContentPage>
  );
};

export default CostsOfHiring;
