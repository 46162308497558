import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import CollapsibleTitle from "./collapsibleTitle";

export const OverviewCollapsibles = () => {
  return (
    <div>
      <CollapsibleTitle title="Paid time off" />
      <div className="content">
        <p>
          In Portugal, employees are entitled to 22 days of paid holiday a year.
          Part time employees are entitled to the same leave as full time
          employees.
        </p>
      </div>
      <CollapsibleTitle title="Public Holidays" />
      <div className="content">
        <p>13 public holidays take place each year.</p>
      </div>
      <CollapsibleTitle title="Maximum working hours and overtime" />
      <div className="content">
        <p>
          Maximum working time is generally 8 hours a day, 40 hours per week.
          Hours above the maximum hours per day are treated as overtime and need
          to be remunerated with an overtime supplement.
        </p>
      </div>
      <CollapsibleTitle title=" Bonus and Expense Payments" />
      <div className="content">
        <p>
          There are 2 bonuses that must be paid in Portugal: 13th & 14th salary.
          This bonuses are subjected to income tax and social security
          withholdings.
        </p>
      </div>

      <CollapsibleTitle title="Parental Leave" />
      <div className="content">
        <p>
          To be eligible for paternity leave, employees must have contributed to
          the Portuguese Social Security System for at least 6 months. The
          Social Security System will pay 100% of the salary for the leave.
        </p>
        <div className="paragraph">
          <h5>Maternity Leave</h5>
          <p>
            120 or 150 calendar days counted from the date of birth. The pay
            during this time is calculated and paid by the social system and can
            be extended up to 90 days.
          </p>
          <h5>Paternity Leave</h5>
          <p>
            Employees can take 20 days’ paternity leave within 30 days following
            the birth. 5 days of this must be taken consecutively immediately
            after the birth of the child.
          </p>
        </div>
      </div>

      <CollapsibleTitle title="Sick Leave" />
      <div className="content">
        <p>
          Employees who have contributed into the Portuguese Social Security
          System for the past 6 months are entitled to paid sick leave after the
          first 3 days of sickness. The first 3 days are unpaid and considered
          as a qualifying period. The pay will be in charge of the state and
          will depend on the length of the sickness:
        </p>
        <div className="paragraph">
          <h5>From 3 to 30 days - 55% of base salary</h5>
          <h5>From 31 to 90 days -60% of base salary </h5>
          <h5> From 91 to 1 year - 70% of base salary</h5>
          <h5> Over 1 year - 75% of base salary</h5>
        </div>
      </div>

      <CollapsibleTitle title="Termination requirements for employees" />
      <div className="content">
        <p>
          Portugal does not allow at-will termination of employment for
          employers. Contracts can be terminated by any party with or without
          cause. If the agreement is terminated unilaterally a justification is
          needed: serious cause, unfit for the role, extinction of the role.{" "}
          <br />
          <br />
          Severance pay is typically 12 days of the base salary for each full
          year of employment.
        </p>
      </div>

      <CollapsibleTitle title="Notice Period" />
      <div className="content">
        <p>
          The minimum notice period is 1 month, and the maximum 4 months. The
          length will depend on the labor relationship.
        </p>
      </div>

      <CollapsibleTitle title="Employment Contract Details" />
      <div className="content">
        <p>
          All documents must be presented to the local authorities. Bilingual
          contracts are allowed and recommended.
        </p>
      </div>

      <CollapsibleTitle title="Probation Period" />
      <div className="content">
        <p>
          Probation periods are determined by the type of contract the worker is
          engaged under and seniority. Probation period is mandatory in
          Portugal.
        </p>
        <div className="paragraph">
          <h5>
            - Fixed-term contracts that last under 6 months can include a
            probationary period from 15 days to 30 days.{" "}
          </h5>
          <h5>
            - Indefinite contracts can include a probationary period of 90 days.{" "}
          </h5>
          <h5>
            - Indefinite contracts for senior management include a probationary
            period up to 240 days for senior management.
          </h5>
        </div>
      </div>

      <CollapsibleTitle title="Minimum wage" />
      <div className="content">
        <p>
          The National Minimum Wage in Portugal is 665 EUR per month, based on a
          14 months calculation.
        </p>
        <StaticImage
          src="../../../images/minimum-wage@3x.png"
          className="wage"
          alt="Minimum wages across countries"
        ></StaticImage>
      </div>
    </div>
  );
};
