import React from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 14px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2.4rem 2.6rem 2.4rem 2.5rem;

  p {
    font-size: 1.6rem !important;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  span {
    height: 2rem;
    border: solid 1px lightgray;
    margin: -2rem 1rem;
  }

  .currency {
    color: var(--green);
  }

  .capital {
    color: var(--red);
  }

  .language {
    color: var(--blue);
  }

  .payroll {
    color: var(--mango);
  }
`;

const OverviewDetails = () => {
  return (
    <Container>
      <p className="currency">Currency: Euro (EUR)</p>
      <span></span>
      <p className="capital">Capital: Lisbon</p>
      <span></span>
      <p className="language">Official language: Portuguese</p>
      <span></span>
      <p className="payroll">Payroll Cycle: Monthly</p>
    </Container>
  );
};

export default OverviewDetails;
