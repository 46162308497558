import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const ReadMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-bottom: 1rem;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: var(--black);
`;

const ReadMore = ({ location }) => {
  return (
    <ReadMoreContainer>
      <p className="read-more">Read more</p>
      <a href={location}>
        <Icon icon="angle-down" size="2x" />
      </a>
    </ReadMoreContainer>
  );
};

export default ReadMore;
